<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
<!--    <base-v-component-->
<!--      heading="Notifications Change Requests List"-->
<!--    />-->

    <base-material-card
      icon="mdi-bell-outline"
      title="Notifications Change Requests List"
      class="px-5 py-3"
    >
      <v-simple-table>
        <filter-list
          :filters="filter"
          :on-change="getChangeRequests">
        </filter-list>

        <tbody>
          <tr v-for="changeRequest in changeRequests" v-bind:key='changeRequest.id'>
            <td>{{ changeRequest.id }}</td>
            <td>{{ changeRequest.notification_id }}</td>
            <td>{{ changeRequest.notification_text }}</td>
            <td>{{ changeRequest.duration.duration | dateDuration }}</td>
            <td>{{ changeRequest.status }}</td>
            <td>{{ changeRequest.created_at }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="text-center">
      <v-pagination
        v-if="pagination.total > 10"
        v-model="query.page"
        :length="pagination.length"
        :total-visible="7"
        @input="getChangeRequests"
        @next="getChangeRequests"
        @previous="getChangeRequests"/>
    </div>
    <div class="py-3" />
  </v-container>
</template>

<script>
  import FilterList from '../../components/base/FilterList'

  export default {
    name: 'ChangeRequestsList',
    components: {FilterList},
    watch: {
      $route() {
        this.getUrlParams();
        this.getChangeRequests();
      }
    },
    data () {
      return {
        changeRequests: [],
        pagination: {
          page: 1,
          length: 0,
          total: 0
        },
        query: {
          page: 1,
          notification_id: this.$route.params.notification_id,
          notification_text : '',
          duration: '',
          status : ''
        },
        filter: [
          {
            name: 'page',
            value: 1,
            type: "pagination"
          },
          {
            name: 'id',
            value: "",
            type: "text_field"
          },
          {
            name: 'notification_id',
            // value: this.$route.query.notification_id,
            value: this.$route.params.notification_id,
            type: "text_field"
          },
          {
            name: 'notification_text',
            value: "",
            type: "text_field"
          },
          {
            name: 'duration',
            value: '',
            type: "text_field",
          },
          {
            name: 'status',
            value: "",
            type: "select",
            items: [
              {text: "pending", value: "pending"},
              {text: "approved", value: "approved"},
              {text : "rejected", value: "rejected"}
            ]
          },
          {
            name: "created_at",
            value: "",
            type: "text_field"
          },
        ]
      }
    },
    created () {
      this.getChangeRequests()
    },
    methods: {
      getUrlParams() {
        if (!Object.keys(this.$route.query).length) {
          this.query = {
            page: 1
          };
          this.filter = [
            {
              name: 'page',
              value: 1,
              type: "pagination"
            },
            {
              name: 'id',
              value: "",
              type: "text_field"
            },
            {
              name: 'notification_id',
              value: "",
              type: "text_field"
            },
            {
              name: 'notification_text',
              value: "",
              type: "text_field"
            },
            {
              name: 'duration',
              value: '',
              type: "text_field",
            },
            {
              name: 'status',
              value: "",
              type: "select",
              items: [
                {text: "pending", value: "pending"},
                {text: "approved", value: "approved"},
                {text : "rejected", value: "rejected"}
              ]
            },
            {
              name: "created_at",
              value: "",
              type: "text_field"
            },
          ];
          this.$root.$emit('refreshParams', this.filter)
        } else {
          this.query = this.$route.query;
          this.filter.forEach(param => {
            param.value = this.query[param['name']];
          });
        }
      },
      getChangeRequests () {
        this.$authApi.get(`/notification-change-requests`, {
          params:this.query
        }).then(res => {
          this.changeRequests = res.data.data.data;
          this.pagination.length = res.data.data.last_page
          this.pagination.total = res.data.data.total;
        })
      },
    },
  }
</script>

<style scoped>

</style>
